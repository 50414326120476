import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../model/user.interface';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Pagination } from '../model/pagination.interface';
import { CompanyService } from './company.service';


@Injectable({ providedIn: 'root' })
export class UserService {

  private _refreshedUsers$: ReplaySubject<User[]> = new ReplaySubject<User[]>();

  get refreshedUsers(): Observable<User[]> {
    return this._refreshedUsers$.asObservable();
  }

  private _currentUser!: User;

  constructor(
    private http: HttpClient,
    private companyService: CompanyService
  ) { }

  get user(): User {
    return this._currentUser;
  }

  createUser(user: Partial<User>): Observable<User> {
    return this.http.post<User>(environment.customers, user);
  }

  getUser(): Observable<User> {
    return this.http
      .get<Pagination<User>>(environment.self)
      .pipe(
        map(({data}: Pagination<User>) => {
          this._currentUser = data[0];
          return data[0];
        })
      );
  }

  getUsers(companyGuid?: string): Observable<User[]> {
    const guid = companyGuid ? companyGuid : this.companyService.currentCompanyGuid;
    return this.http
      .get<Pagination<User>>(
        `${environment.companies}/${guid}/customers`
      )
      .pipe(
        map(({data}: Pagination<User>) => data)
      );
  }

  getUserForEditEmail(
    formValue: {
      userName: string;
      userEmail: string;
    }): Observable<User> {
      let params = new HttpParams();

      if (formValue.userEmail) {
        params = params.append('email', formValue.userEmail);
      }
      if (formValue.userName) {
        params = params.append('name', formValue.userName);
      }

      return this.http
        .get<Pagination<User>>(
          `${environment.companies}/${this.companyService.currentCompanyGuid}/customers_admin_editing`,
          { params }
          )
        .pipe(
          map(({data}: Pagination<User>) => data[0])
        );
  }

  editUserEmail(userId: number, userEmail: string): Observable<User[]> {
    const payload = { email: userEmail };
    return this.http
      .put<Pagination<User>>(`${environment.customers}/${userId}`, payload)
      .pipe(
        map(({data}: Pagination<User>) => data)
      );
  }

  upgradeUserRole(
    userId: number,
    userData: any,
    companyGuid: string,
  ): Observable<User[]> {
    return this.http.put<User[]>(`${environment.customers}/${userId}/upgrade_to_enterprise_manager`, userData)
    .pipe(
      switchMap(() => {
        return this.getUsers( companyGuid);
      }),
      tap((users: User[]) => {
        this._refreshedUsers$.next(users);
      }),
    );
  }

  changeAgreementPermissionForUser(user: User): Observable<User> {
    const endpoint = user.allowedAgreementForms ?
      `customers/${user.guid}/disable_agreement_forms` : `customers/${user.guid}/allow_agreement_forms`;
    return this.http.post<User>(`${environment.api}/${endpoint}`, null);
  }
}
